import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((_theme: Theme) => ({
  main: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f5f5f5',
    flexDirection: 'column',
    textAlign: 'center',
  },
  title: {
    fontSize: 32,
    fontWeight: 'bold',
    fontFamily: 'Roboto, sans-serif',
    color: '#333333',
    marginBottom: 16,
  },
  text: {
    fontSize: 18,
    fontWeight: 'normal',
    fontFamily: 'Roboto, sans-serif',
    letterSpacing: 0.5,
    color: '#555555',
    marginBottom: 24,
  },
  returnButton: {
    padding: '12px 24px',
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Roboto, sans-serif',
    color: '#FFFFFF',
    backgroundColor: '#053396',
    border: 'none',
    borderRadius: 4,
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: '#052076',
    },
    marginBottom: 16,
  },
}))

export default useStyles
