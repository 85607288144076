import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import * as jose from 'jose'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useStateValue } from '../../providers/StateProvider'
import useStyles from './style'

export default function MetabaseIframe(): JSX.Element {
  const [{ currentTicketOffice }] = useStateValue()
  const history = useHistory()
  const classes = useStyles()
  const [iframe, setIframe] = useState('')
  const [accessAllowed, setAccessAllowed] = useState(false)

  async function generateIframeURL() {
    const unidade_id =
      currentTicketOffice?.setor?.unidade?.slug_unidade ||
      currentTicketOffice?.unidade?.slug_unidade
    console.log({ unidade_id })

    const REACT_APP_METABASE_SITE_URL =
      process.env.REACT_APP_METABASE_SITE_URL || ''
    const REACT_APP_METABASE_SECRET_KEY =
      process.env.REACT_APP_METABASE_SECRET_KEY || ''

    const payload: jose.JWTPayload = {
      resource: { dashboard: 11 },
      params: {},
      exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
    }
    const token = await new jose.SignJWT(payload)
      .setProtectedHeader({ alg: 'HS256' })
      .sign(new TextEncoder().encode(REACT_APP_METABASE_SECRET_KEY))
    const iframeUrl =
      REACT_APP_METABASE_SITE_URL +
      '/embed/dashboard/' +
      token +
      '#bordered=false&titled=false'
    setIframe(iframeUrl)
  }
  useEffect(() => {
    if (currentTicketOffice?.tipo === 'Consulta') {
      setAccessAllowed(true)
      generateIframeURL()
    } else {
      setAccessAllowed(false)
    }
  }, [currentTicketOffice])

  const returnButton = () => {
    history.push('/home')
  }
  return (
    <Box>
      {accessAllowed ? (
        <iframe
          title="Dados"
          src={iframe}
          frameBorder={0}
          width={'100%'}
          height={850}
        />
      ) : (
        <>
          <Box className={classes.main}>
            <Typography variant="h4" className={classes.title}>
              Guichê sem permissão
            </Typography>
            <Typography variant="body1" className={classes.text}>
              O guichê <strong>{currentTicketOffice?.nome}</strong> não tem
              permissão para acessar os relatórios.
            </Typography>
            <Button className={classes.returnButton} onClick={returnButton}>
              Voltar para a Página Inicial
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}
